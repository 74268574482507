<template>
  <v-container>
    <AppraisalBreadcrumbs />
    <router-view />
  </v-container>
</template>

<script>
import AppraisalBreadcrumbs from "../components/AppraisalBreadcrumbs";
export default {
  name: "Appraisal",
  components: { AppraisalBreadcrumbs },
};
</script>

<style scoped></style>
